import { Plural, Trans, useLingui } from '@lingui/react/macro';
import {
  Box,
  BoxProps,
  Divider,
  Paper,
  Popover,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import { ClassNameMap, createStyles, makeStyles } from '@mui/styles';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import ArrowLeftIcon from '@watershed/icons/components/ArrowLeft';
import ArrowRightIcon from '@watershed/icons/components/ArrowRight';
import HelpIcon from '@watershed/icons/components/Help';
import InboxIcon from '@watershed/icons/components/Inbox';
import {
  LoginQueryParams,
  routeForReport,
  LOGIN_CUSTOMER_RELATION_OPTIONS,
  routeForCustomerHub,
  PRIVACY_POLICY_URL,
  DATA_PORTAL_TERMS_URL,
  LoginCustomerRelationOption,
  SUPPORT_EMAIL_LINK,
  SUPPORT_EMAIL_ADDRESS,
} from '@watershed/shared-universal/dashboardRoutes';
import usePrefetchRoute from '@watershed/shared-frontend/hooks/usePrefetchRoute';
import {
  submitEmailPassword,
  sendMagicLink,
} from '@watershed/shared-frontend/utils/loginUtils';
import getQueryStringRedirect, {
  useQueryStringRedirect,
} from '@watershed/shared-frontend/utils/getQueryStringRedirect';
import { parseQueryParam } from '@watershed/shared-universal/utils/queryParamUtils';
import {
  AuthError,
  getAuthErrorMessage,
  MAGIC_LINK_EXPIRY_MINUTES,
} from '@watershed/shared-universal/authConstants';
import assertNever from '@watershed/shared-util/assertNever';
import isValidEmail from '@watershed/shared-universal/utils/isValidEmail';
import parseEmail from '@watershed/shared-universal/utils/parseEmail';
import Button from '@watershed/ui-core/components/Button';
import ProgressButton from '@watershed/ui-core/components/ProgressButton';
import TextField, {
  TextFieldNonFormik,
} from '@watershed/ui-core/components/Form/TextField';
import { Form, Formik } from 'formik';
import invariant from 'invariant';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import RedirectAuthenticatedUsers from '../components/RedirectAuthenticatedUsers';
import { Layouts } from '../layouts/LayoutEnum';
import LoginHeader from '@watershed/shared-frontend/components/LoginHeader';
import { getWorkosHandoffUrl } from '@watershed/shared-universal/utils/workosUtils';
import getPathWithQuery from '@watershed/shared-universal/utils/getPathWithQuery';
import { withOptionalRouteParams } from '@watershed/shared-frontend/utils/withRouteParams';
import TextLink from '@watershed/ui-core/components/TextLink';
import Callout from '@watershed/shared-frontend/components/Callout';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import stringToEnum from '@watershed/shared-universal/utils/stringToEnum';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';
import {
  EmailFormWithDomains,
  SupplierLoginPageContainer,
} from '../components/surveys/SupplierLoginPage';
import { useOwner } from '@watershed/shared-frontend/hooks/useOwner';
import { Teams } from '@watershed/constants/teams';
import wsFetch from '@watershed/shared-frontend/utils/wsFetch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centeredCard: {
      padding: theme.spacing(0, 4, 5),
      display: 'flex',
      flexDirection: 'column',
      border: `0.5px solid ${theme.palette.grey30}`,
      borderRadius: '10px',
      boxShadow:
        '0px 1px 2px 0px rgba(79, 89, 110, 0.16), 0px 0px 16px 1px rgba(79, 89, 110, 0.06)',
      '& form': {
        display: 'contents',
      },
    },
    footerLink: {
      color: theme.palette.grey50,
    },
  })
);

export interface RecipientOrgMetadata {
  orgDomains: Array<string>;
  orgName?: string | null;
  customerName: string;
  demoOrg?: boolean | null;
}

interface OrgMetadata {
  orgName: string;
  ssoRequired: boolean;
  magicLinkEnabled: boolean;
  googleAuthEnabled: boolean;
  workosSsoEnabled: boolean;
  orgIconUrl?: string;
  customerName?: string;
  demoOrg?: boolean;
  isDataPortalLogin?: boolean;
}
export interface EmailLoginState {
  state: 'email';
  email?: string;
  fetching: boolean;
  error?: string;
}

interface GenericAuthLoginState {
  state: 'genericAuth';
  email: string;
  fetching: boolean;
  error?: string;
  metadata?: OrgMetadata | undefined;
  customerName?: string;
}

interface PasswordLoginState {
  state: 'password';
  email: string;
  fetching: boolean;
  error?: string;
  metadata?: OrgMetadata | undefined;
}

// See workspaces/server/routes/auth/saml.ts for commentary on our SAML implementation
interface NonWorkosSsoRequiredLoginState {
  state: 'nonWorkosSsoRequired';
  email: string;
  fetching: boolean;
  error?: string;
  metadata: OrgMetadata;
}
interface WorkosLoginState {
  state: 'workos';
  email: string;
  fetching: boolean;
  error?: string;
  metadata: OrgMetadata;
}

interface MagicLinkPendingState {
  state: 'magicLinkPending';
  email: string;
  error?: string;
  metadata: OrgMetadata;
}

interface ErrorState {
  state: 'error';
  email: string;
  error: string;
}

/**
 * This whole notion of Login state is about starting with collecting a user's
 * email and then making a query to determine what login options are appropriate
 * for that user.
 *
 * The initial state for a new user is an empty form asking for the email, but
 * once a user has logged in, we do things like set the email to local storage
 * so that it persists over time.
 *
 * See workspaces/server/routes/auth/email.ts for information on how we gather
 * all of this.
 */
type AuthenticationType = 'google' | 'password' | 'workos';
export type LoginState =
  | EmailLoginState
  | GenericAuthLoginState
  | PasswordLoginState
  | NonWorkosSsoRequiredLoginState
  | WorkosLoginState
  | MagicLinkPendingState
  | ErrorState;

const isDevelopment = process.env.NODE_ENV === 'development';

function isMagicLinkEnabled(metadata: OrgMetadata | undefined) {
  return metadata?.magicLinkEnabled === true;
}

function isGoogleAuthEnabled(metadata: OrgMetadata | undefined) {
  return metadata?.googleAuthEnabled === true;
}

async function fetchOrgMetadata(
  customerCompanyId: string,
  query: { recipientCompanyId?: string | null }
): Promise<RecipientOrgMetadata | undefined> {
  const url = getPathWithQuery(
    `/auth/org/${encodeURIComponent(customerCompanyId)}`,
    query
  );

  const response = await wsFetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }
  const data = await response.json();
  const metadata: RecipientOrgMetadata | undefined = data.customerName
    ? {
        orgName: data.orgName as string | undefined,
        customerName: data.customerName as string,
        demoOrg: data.demoOrg as boolean | undefined,
        orgDomains: data.orgDomains as Array<string>,
      }
    : undefined;

  return metadata;
}

async function fetchEmailMetadata(
  email: string,
  query: {
    customerCompanyId?: string | null;
    recipientCompanyId?: string | null;
  }
): Promise<OrgMetadata | undefined> {
  const url = getPathWithQuery(
    `/auth/email/${encodeURIComponent(email)}`,
    query
  );
  const response = await wsFetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  const data = await response.json();
  const metadata: OrgMetadata | undefined = data.orgName
    ? {
        ssoRequired: !!data.ssoRequired,
        magicLinkEnabled: !!data.magicLinkEnabled,
        googleAuthEnabled: !!data.googleAuthEnabled,
        workosSsoEnabled: !!data.workosSsoEnabled,
        orgName: data.orgName as string,
        orgIconUrl: data.orgIconUrl as string | undefined,
        customerName: data.customerName as string | undefined,
        demoOrg: data.demoOrg as boolean | undefined,
        isDataPortalLogin: data.isDataPortalLogin as boolean | undefined,
      }
    : undefined;

  return metadata;
}

function EmailForm({
  state,
  onSubmit,
  onError,
}: {
  state: EmailLoginState;
  onSubmit: (email: string) => Promise<void>;
  onError: (error: string) => void;
}) {
  return (
    <Formik
      initialValues={{ email: state.email || '' }}
      onSubmit={(values) => {
        Analytics.action('emailForm', {
          email: values.email,
        });
        if (!isValidEmail(values.email)) {
          Analytics.error('emailForm', {
            email: values.email,
            error: 'Invalid email',
          });
          onError('Invalid email');
          return;
        }

        void onSubmit(values.email);
      }}
    >
      <Form style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Box paddingRight={1} flexGrow={1}>
          <TextField
            id="email"
            type="email"
            label={<Trans context="Form field label">Email</Trans>}
            visuallyHideLabel
            autoComplete="email"
            enterKeyHint="next"
            autoFocus
          />
        </Box>
        <Button
          isIcon
          type="submit"
          color="primary"
          title={state.fetching ? 'Loading' : 'Submit'}
          aria-busy={state.fetching}
          size="large"
          data-testid={TestIds.SubmitEmailButton}
        >
          {state.fetching ? (
            <CircularProgress size={16} />
          ) : (
            <ArrowRightIcon size={16} />
          )}
        </Button>
      </Form>
    </Formik>
  );
}

/**
 * It came up in https://app.graphite.dev/github/pr/watershed-climate/ghg/60293/More-localisation-coverage#comment-PRRC_kwDODVJlBM5j9zDq
 * that this UX is potentially tricky across languages. We may want to rethink the UX here for i18n clarity.
 */
const AuthDivider = () => (
  <div
    aria-hidden
    style={{
      display: 'grid',
      gridGap: 24,
      gridTemplateColumns: '1fr auto 1fr',
      alignItems: 'center',
      padding: '24px 0 24px 0',
    }}
  >
    <Divider />
    <Typography align="center" variant="body3" color="text.secondary">
      <Trans context="List separator in the UI separating different login methods. Should be something that can make clear to the user that both login methods are not needed.">
        OR
      </Trans>
    </Typography>
    <Divider />
  </div>
);

function WorkosAuthButton({ email }: { email: string }) {
  const redirect = useQueryStringRedirect();

  return (
    <Button
      size="large"
      color="primary"
      onClick={() => {
        Analytics.action('login.workosSsoButton');
        // Is this enough time for the Mixpanel event to fire? I'm not sure!
        setTimeout(() => {
          const [, domain] = parseEmail(email);
          const url = getWorkosHandoffUrl({ email, domain, redirect });
          window.location.replace(url);
        }, 32);
      }}
      fullWidth
      data-testid={TestIds.LoginWithSsoButton}
    >
      <Trans>Log in with SSO</Trans>
    </Button>
  );
}

export function DisabledEmailFieldWithChange({
  emailAddress,
  onReset,
  authenticationType,
}: {
  emailAddress: string;
  onReset: () => void;
  authenticationType: AuthenticationType;
}) {
  const { t } = useLingui();
  return (
    <Box position="relative" marginBottom={2}>
      <Button
        onClick={() => {
          Analytics.action('login.changeEmail', { authenticationType });
          onReset();
        }}
        variant="text"
        title={t({ message: 'Not your email?', context: 'button title' })}
        color="primary"
        size="small"
        style={{
          position: 'absolute',
          zIndex: 1,
          bottom: 2,
          right: 2,
        }}
      >
        <Trans context="Button copy to allow the user to switch from the currently listed email address to another email address.">
          Edit
        </Trans>
      </Button>
      <TextFieldNonFormik id="email" value={emailAddress} disabled />
    </Box>
  );
}

function WorkosAuthSection({
  email,
  onReset,
  isDataPortalLogin,
}: {
  email: string;
  onReset: () => void;
  isDataPortalLogin: boolean | undefined;
}) {
  return (
    <Box>
      <DisabledEmailFieldWithChange
        emailAddress={email}
        onReset={onReset}
        authenticationType="workos"
      />
      {isDataPortalLogin && <DataPortalTerms email={email} />}
      <WorkosAuthButton email={email} />
    </Box>
  );
}

function PasswordAuthSection({
  email,
  onSubmit,
  fetching,
  isDataPortalLogin,
}: {
  email: string;
  onSubmit: (password: string, email: string) => Promise<void>;
  fetching: boolean;
  isDataPortalLogin: boolean | undefined;
}) {
  const { t } = useLingui();
  return (
    <Formik
      initialValues={{ password: '' }}
      onSubmit={(values) => {
        Analytics.action('passwordAuth', {
          email,
        });
        void onSubmit(values.password, email);
      }}
    >
      <Form>
        <TextField
          id="password"
          label={<Trans context="Form field label">Password</Trans>}
          visuallyHideLabel
          data-testid={TestIds.PasswordInput}
          type="password"
          placeholder={t({
            message: 'Password',
            context: 'Login form password field placeholder',
          })}
          autoFocus
          disabled={fetching}
        />
        {isDataPortalLogin && (
          <Box sx={{ marginTop: 2 }}>
            <DataPortalTerms email={email} />
          </Box>
        )}
        <Box paddingTop={2}>
          <ProgressButton
            type="submit"
            fullWidth
            label={<Trans context="button label">Log in</Trans>}
            isInProgress={fetching}
            disabled={fetching}
            data-testid={TestIds.LoginButton}
          />
        </Box>
      </Form>
    </Formik>
  );
}

function GoogleAuthSection({
  email,
  fetching,
  isPrimary = false,
}: {
  onError: (error: string) => void;
  email: string;
  fetching: boolean;
  isPrimary?: boolean;
}) {
  const redirect = useQueryStringRedirect();

  return (
    <Button
      size="large"
      color={isPrimary ? 'primary' : 'secondary'}
      onClick={() => {
        Analytics.action('googleLogin');
        // Is this enough time for the Mixpanel event to fire? I'm not sure!
        setTimeout(() => {
          const [, domain] = parseEmail(email);
          const url = getWorkosHandoffUrl({
            domain,
            provider: 'GoogleOAuth',
            redirect,
          });
          window.location.replace(url);
        }, 32);
      }}
      fullWidth
      disabled={fetching || isDevelopment}
      tooltip={
        isDevelopment
          ? 'Google SSO does not work on localhost. Use password log-in.'
          : undefined
      }
    >
      <Trans>Log in with Google</Trans>
    </Button>
  );
}

function MagicLinkSection({
  onClick,
  isLoading,
  isPrimary = false,
  ...boxProps
}: {
  onClick: () => void;
  isLoading: boolean;
  isPrimary?: boolean;
} & BoxProps) {
  return (
    <Box {...boxProps}>
      <ProgressButton
        color={isPrimary ? 'primary' : 'secondary'}
        variant={isPrimary ? 'contained' : 'text'}
        onClick={onClick}
        fullWidth
        label={<Trans context="button label">Send log-in link</Trans>}
        isInProgress={isLoading}
      />
    </Box>
  );
}

function LoginWithPasswordButton({
  onClick,
  ...boxProps
}: { onClick: () => void } & BoxProps) {
  return (
    <Box {...boxProps}>
      <Button
        fullWidth
        onClick={onClick}
        data-testid={TestIds.LoginWithPasswordButton}
      >
        <Trans>Log in with password</Trans>
      </Button>
    </Box>
  );
}

function MagicLinkPendingSection({
  emailAddress,
  onReset,
}: {
  emailAddress: string | undefined;
  onReset: () => void;
}) {
  const [popoverAnchor, setPopoverAnchor] = useState<null | HTMLElement>(null);
  return (
    <Box textAlign="center">
      <InboxIcon color={(theme) => theme.palette.info.main} size={48} />
      <Typography variant="h2" gutterBottom>
        <Trans context="This is an instruction to the user during the Magic Link login feature. An email has been sent to their inbox and now they need to look for that email for a link to log in.">
          Check your email to verify your log-in
        </Trans>
      </Typography>
      <Typography variant="body2" paragraph>
        <Plural
          value={MAGIC_LINK_EXPIRY_MINUTES}
          one={`Email sent to ${emailAddress}. This link will expire in # minute.`}
          other={`Email sent to ${emailAddress}. This link will expire in # minutes.`}
        />
      </Typography>
      <Button startIcon={<ArrowLeftIcon />} onClick={onReset} fullWidth>
        <Trans context="Button copy to allow the user to switch from the currently listed email address to another email address.">
          Change email
        </Trans>
      </Button>
      <Button
        variant="text"
        size="small"
        startIcon={<HelpIcon size={20} />}
        fullWidth
        style={{ marginTop: 16, marginBottom: -12 }}
        onClick={(e) => setPopoverAnchor(e.currentTarget)}
      >
        <Trans context="Button copy supporting the Magic Link log-in feature. If an email has not been received, this allows the user to resend.">
          Don’t see an email?
        </Trans>
      </Button>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={popoverAnchor}
        open={!!popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
      >
        <Box padding={2} maxWidth={300} textAlign="center">
          <Typography variant="h4" gutterBottom>
            <Trans>Please check your spam folder.</Trans>
          </Typography>
          <Typography>
            <Trans>
              If you’re still not seeing the email, contact{' '}
              <TextLink href={SUPPORT_EMAIL_LINK}>
                {SUPPORT_EMAIL_ADDRESS}
              </TextLink>{' '}
              for help.
            </Trans>
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
}

function LoginFooter({ classes }: { classes: ClassNameMap }) {
  return (
    <>
      <TextLink
        href="https://watershed.com"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.footerLink}
      >
        <Trans context="company name">Watershed</Trans>
      </TextLink>
      <TextLink
        href={PRIVACY_POLICY_URL}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.footerLink}
      >
        <Trans context="Link copy to privacy page">Privacy</Trans>
      </TextLink>
      <TextLink
        href={DATA_PORTAL_TERMS_URL}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.footerLink}
      >
        <Trans context="Link copy to data portal terms and conditions">
          Data Portal Terms
        </Trans>
      </TextLink>
      <TextLink
        href={SUPPORT_EMAIL_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.footerLink}
      >
        <Trans context="Link copy to support email">Support</Trans>
      </TextLink>
    </>
  );
}

function useCustomerRelation(): LoginCustomerRelationOption | null {
  const router = useRouter();
  const customerRelation = parseQueryParam(router.query.customerRelation);

  if (customerRelation === null) {
    return null;
  }

  return stringToEnum(LOGIN_CUSTOMER_RELATION_OPTIONS, customerRelation, null);
}

const EMAIL_LOCALSTORAGE_KEY = 'loginEmail';

function LoginPage({
  authError,
  email: emailFromQuery,
  adminName,
  adminEmail,
  watershedGeneric,
}: {
  authError: string | null;
  email: string | null;
  adminName: string | null;
  adminEmail: string | null;
  watershedGeneric: string | null;
}) {
  const classes = useStyles();
  const router = useRouter();

  // Prefetch the index and home experiences right out of the gates
  usePrefetchRoute('/');
  usePrefetchRoute('/home');

  const query: LoginQueryParams = router.query;
  const email =
    emailFromQuery ||
    localStorage?.getItem(EMAIL_LOCALSTORAGE_KEY) ||
    undefined;
  const customerCompanyId = parseQueryParam(query.customerId);
  const recipientCompanyId = parseQueryParam(query.recipientCompanyId);
  const customerRelation = useCustomerRelation();

  const [submittedHelpRequest, setSubmittedHelpRequest] = useState(false);
  const [state, setState] = useState<LoginState>({
    state: 'email',
    email,
    fetching: false,
    error: authError ? getAuthErrorMessage(authError) : undefined,
  });

  const [recipientMetadata, setRecipientMetadata] =
    useState<RecipientOrgMetadata>({
      orgDomains: [],
      orgName: null,
      customerName: '',
    });

  /**
   * Reset our state if we have a mismatch between the email param in
   * the URL and our internal state.
   */
  useEffect(() => {
    if (state.email !== email) {
      setState({
        state: 'email',
        email,
        fetching: false,
        error: state.error,
      });
    }
  }, [state.email, email, state.error]);

  const fetchOrgDomainsAndSetState = async (
    recipientCompanyId: string | null,
    customerCompanyId: string
  ) => {
    let metadata;
    try {
      metadata = await fetchOrgMetadata(customerCompanyId, {
        recipientCompanyId,
      });
    } catch (ex) {
      const error = ex as Error;
      Analytics.error('fetchOrgData', {
        error: error.message,
      });
    }
    if (metadata !== undefined) {
      setRecipientMetadata(metadata);
    }
  };

  const fetchEmailMetadataAndSetState = async (
    email: string,
    error: string | undefined
  ) => {
    setState({
      state: 'email',
      email,
      fetching: true,
      error,
    });

    let pendingState:
      | GenericAuthLoginState
      | NonWorkosSsoRequiredLoginState
      | WorkosLoginState
      | ErrorState = {
      state: 'genericAuth',
      email,
      fetching: false,
      error,
    };

    try {
      const metadata = await fetchEmailMetadata(email, {
        customerCompanyId,
        recipientCompanyId,
      });

      if (metadata?.workosSsoEnabled) {
        pendingState = {
          state: 'workos',
          email,
          fetching: false,
          metadata,
          error,
        };
      } else if (metadata?.ssoRequired) {
        pendingState = {
          state: 'nonWorkosSsoRequired',
          email,
          fetching: false,
          metadata,
          error,
        };
      } else {
        pendingState = {
          state: 'genericAuth',
          email,
          fetching: false,
          metadata,
          error,
        };
      }
    } catch (ex) {
      const error = ex as Error;
      pendingState = {
        state: 'error',
        email,
        error: error.message,
      };
      Analytics.error('fetchEmailMetadata', {
        error: error.message,
      });
    }

    setState(pendingState);
  };

  /**
   * If email is already set (whether via url or local storage), automatically
   * kick off the email step.
   */
  useEffect(() => {
    if (email && isValidEmail(email)) {
      void fetchEmailMetadataAndSetState(email, state.error);
    }
    // This is explicitly leaving out `state.error`, because when it changes it
    // can cause rerenders that we don't want. This is the downfall of hooks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (
      customerRelation === LOGIN_CUSTOMER_RELATION_OPTIONS.SUPPLIER &&
      customerCompanyId
    ) {
      void fetchOrgDomainsAndSetState(recipientCompanyId, customerCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerRelation, recipientCompanyId]);

  const onError = (error: string) => {
    setState((state) => ({ ...state, error }));
  };

  const metadata =
    state.state === 'error' || state.state === 'email'
      ? undefined
      : state.metadata;
  const customerName = metadata?.customerName;

  const queryStringRedirect = getQueryStringRedirect(router.query) || '';
  const isTryingToAccessReport = queryStringRedirect.includes(
    routeForReport('')
  );
  const isTryingToAccessCustomerHub = queryStringRedirect.includes(
    routeForCustomerHub('')
  );

  const handleChangeEmail = () => {
    setState({
      state: 'email',
      email: '',
      fetching: false,
    });
    // TODO: URGENT Please fix this by await-ing or void-ing this line.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({
      pathname: location.pathname,
      query: omit(router.query, ['email', 'authError']),
      hash: location.hash,
    });
  };

  const handleLoginWithPassword = () => {
    invariant(
      state.state === 'genericAuth' || state.state === 'workos',
      'handleLoginWithPassword state'
    );
    setState({
      state: 'password',
      email: state.email,
      metadata: state.metadata,
      fetching: false,
    });
    Analytics.action('login.loginWithPasswordOnWorkos', {
      email: state.email,
    });
  };

  const handleSubmitEmail = async (email: string) => {
    localStorage.setItem(EMAIL_LOCALSTORAGE_KEY, email);
    // TODO: URGENT Please fix this by await-ing or void-ing this line.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({
      pathname: location.pathname,
      query: {
        ...omit(router.query, 'authError'),
        email,
      },
      hash: location.hash,
    });

    // TODO: URGENT Please fix this by await-ing or void-ing this line.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchEmailMetadataAndSetState(email, undefined);
  };

  const handleSubmitPassword = async (password: string, email: string) => {
    setState((state) => ({ ...state, fetching: true }));
    try {
      const result = await submitEmailPassword(email, password);

      setState((state) => ({ ...state, fetching: false }));
      const redirectPath =
        result?.redirect ?? (getQueryStringRedirect(router.query) || '/');
      // We do a hard page-load (instead of a client-side redirect) to avoid
      // potential bugs related to authentication state by refreshing that state
      // completely.
      window.location.replace(redirectPath);
    } catch (ex) {
      const error = ex as Error;
      Analytics.error('submitAuth', {
        error: error.message,
      });
      setState((state) => ({
        ...state,
        error: error.message,
        fetching: false,
      }));
    }
  };

  const handleSendMagicLink = async () => {
    invariant(state.email, 'email is present');
    invariant('metadata' in state && state.metadata, 'metadata is present');
    Analytics.action('login.sendMagicLink', { email: state.email });

    setState((state) => ({ ...state, fetching: true }));

    try {
      await sendMagicLink({
        email: state.email,
        redirect: getQueryStringRedirect(router.query),
      });
      setState({
        state: 'magicLinkPending',
        email: state.email,
        metadata: state.metadata,
      });
    } catch (err) {
      setState((state) => ({
        ...state,
        fetching: false,
        error: (err as Error).message,
      }));
    }
  };
  const LoginComponent = () => {
    switch (state.state) {
      case 'email':
        return customerRelation === LOGIN_CUSTOMER_RELATION_OPTIONS.SUPPLIER &&
          recipientMetadata.orgDomains &&
          recipientMetadata.orgDomains.length > 0 ? (
          <EmailFormWithDomains
            state={state}
            domains={recipientMetadata.orgDomains}
            onSubmit={handleSubmitEmail}
            onError={onError}
            recipientMetadata={recipientMetadata}
            setSubmittedHelpRequest={setSubmittedHelpRequest}
          />
        ) : (
          <EmailForm
            state={state}
            onSubmit={handleSubmitEmail}
            onError={onError}
          />
        );
      case 'genericAuth': {
        /**
         * Has WorkOS ID, but no non-magic-link WorkOS connection; this
         * means that the user can use magic links but not WorkOS SSO.
         */
        const hasMagicLinks = isMagicLinkEnabled(state.metadata);
        if (hasMagicLinks) {
          return (
            <>
              <DisabledEmailFieldWithChange
                emailAddress={state.email}
                onReset={handleChangeEmail}
                authenticationType="password"
              />
              {state.metadata?.isDataPortalLogin && (
                <Box sx={{ mb: 2 }}>
                  <DataPortalTerms email={email} />
                </Box>
              )}
              <MagicLinkSection
                onClick={() => handleSendMagicLink()}
                isLoading={state.fetching}
                isPrimary
              />
              {isGoogleAuthEnabled(state.metadata) && (
                <>
                  <AuthDivider />
                  <GoogleAuthSection
                    onError={onError}
                    email={state.email}
                    fetching={state.fetching}
                  />
                </>
              )}
              <LoginWithPasswordButton
                onClick={handleLoginWithPassword}
                marginTop={1}
              />
            </>
          );
        } else {
          return (
            <>
              <DisabledEmailFieldWithChange
                emailAddress={state.email}
                onReset={handleChangeEmail}
                authenticationType="password"
              />
              {state.metadata?.isDataPortalLogin && (
                <Box sx={{ mb: 2 }}>
                  <DataPortalTerms email={email} />
                </Box>
              )}
              {isGoogleAuthEnabled(state.metadata) && (
                <>
                  <GoogleAuthSection
                    onError={onError}
                    fetching={state.fetching}
                    email={state.email}
                    isPrimary
                  />
                  <AuthDivider />
                </>
              )}
              <LoginWithPasswordButton onClick={handleLoginWithPassword} />
            </>
          );
        }
      }
      case 'password':
        return (
          <>
            <DisabledEmailFieldWithChange
              emailAddress={state.email}
              onReset={handleChangeEmail}
              authenticationType="password"
            />
            <PasswordAuthSection
              email={state.email}
              onSubmit={handleSubmitPassword}
              fetching={state.fetching}
              isDataPortalLogin={state.metadata?.isDataPortalLogin}
            />
          </>
        );
      case 'nonWorkosSsoRequired': {
        /**
         * Has WorkOS ID, but no non-magic-link WorkOS connection; this
         * means that the user can use magic links but not WorkOS SSO.
         */
        const hasMagicLinks = isMagicLinkEnabled(state.metadata);
        const hasGoogleAuth = isGoogleAuthEnabled(state.metadata);
        return (
          <>
            <DisabledEmailFieldWithChange
              emailAddress={state.email}
              onReset={handleChangeEmail}
              authenticationType="google"
            />
            {state.metadata?.isDataPortalLogin && (
              <Box sx={{ mb: 2 }}>
                <DataPortalTerms email={email} />
              </Box>
            )}
            <Stack divider={<AuthDivider />}>
              {hasGoogleAuth && (
                <GoogleAuthSection
                  onError={onError}
                  email={state.email}
                  fetching={state.fetching}
                  isPrimary
                />
              )}
              {hasMagicLinks && authError !== AuthError.PermissionsRequired && (
                <MagicLinkSection
                  isPrimary={!hasGoogleAuth}
                  onClick={() => handleSendMagicLink()}
                  isLoading={state.fetching}
                />
              )}
            </Stack>
          </>
        );
      }
      case 'workos':
        const hasMagicLinks = isMagicLinkEnabled(state.metadata);
        if (state.metadata.ssoRequired && state.metadata.workosSsoEnabled) {
          return (
            <>
              <WorkosAuthSection
                email={state.email}
                onReset={handleChangeEmail}
                isDataPortalLogin={state.metadata.isDataPortalLogin}
              />
              {hasMagicLinks && (
                <>
                  <AuthDivider />
                  <MagicLinkSection
                    onClick={() => handleSendMagicLink()}
                    isLoading={state.fetching}
                  />
                </>
              )}
            </>
          );
        } else {
          return (
            <>
              <WorkosAuthSection
                email={state.email}
                onReset={handleChangeEmail}
                isDataPortalLogin={state.metadata.isDataPortalLogin}
              />
              <AuthDivider />
              {hasMagicLinks && (
                <MagicLinkSection
                  onClick={() => handleSendMagicLink()}
                  isLoading={state.fetching}
                />
              )}
              <LoginWithPasswordButton
                onClick={handleLoginWithPassword}
                marginTop={1}
              />
            </>
          );
        }
      case 'magicLinkPending':
        return (
          <MagicLinkPendingSection
            emailAddress={state.email}
            onReset={handleChangeEmail}
          />
        );
      case 'error':
        return (
          <DisabledEmailFieldWithChange
            emailAddress={state.email}
            onReset={handleChangeEmail}
            authenticationType="google"
          />
        );
      default:
        assertNever(state);
    }
  };

  return (
    <RedirectAuthenticatedUsers>
      <Box
        sx={{
          width: 512,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isTryingToAccessCustomerHub &&
        customerRelation &&
        customerRelation === LOGIN_CUSTOMER_RELATION_OPTIONS.SUPPLIER ? (
          <Paper
            sx={{
              width: '800px',
              height: '600px',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Box display="flex" flexDirection="column" sx={{ m: 4, mr: 0 }}>
              {state.error && authError !== AuthError.PermissionsRequired && (
                <ErrorBox marginBottom={2} marginRight={4}>
                  {state.error}
                </ErrorBox>
              )}
              <SupplierLoginPageContainer
                recipientName={recipientMetadata.orgName ?? null}
                // Fix this
                rootName={recipientMetadata.customerName ?? null}
                setState={setState}
                LoginComponent={LoginComponent}
                submittedHelpRequest={submittedHelpRequest}
                state={state}
              />
            </Box>
            <Box
              display="flex"
              sx={{
                px: 2,
                py: 2,
                gap: 2,
                bottom: 0,
                position: 'absolute',
              }}
            >
              <LoginFooter classes={classes} />
            </Box>
          </Paper>
        ) : (
          <>
            <Paper component="main" className={classes.centeredCard}>
              <LoginHeader
                title={
                  <Trans context="login header">Log in to Watershed</Trans>
                }
                orgName={metadata?.orgName}
                orgIconUrl={metadata?.orgIconUrl}
                error={
                  // Don't actually show the error box if we send a permission request to org admins
                  authError !== AuthError.PermissionsRequired
                    ? state.error
                    : null
                }
              />
              {isTryingToAccessReport && (
                <Typography sx={{ mb: 3, textAlign: 'center' }}>
                  <Trans>
                    Author your annual ESG reports in Watershed and showcase
                    your company’s sustainability efforts and progress.
                  </Trans>
                </Typography>
              )}
              {isTryingToAccessCustomerHub &&
                customerName &&
                (watershedGeneric ? (
                  <>
                    <Typography sx={{ mb: 3, textAlign: 'center' }}>
                      <Trans>
                        The Watershed Portal will allow you to estimate your
                        carbon footprint and compare against your industry’s
                        average.
                      </Trans>
                    </Typography>
                    <Typography sx={{ mb: 3, textAlign: 'center' }}>
                      <Trans>
                        To access the Watershed Portal, please enter your email
                        address. Watershed will email you a link that you can
                        use to securely sign in through your email account.
                      </Trans>
                    </Typography>
                  </>
                ) : (
                  <>
                    {customerRelation &&
                      customerRelation ===
                        LOGIN_CUSTOMER_RELATION_OPTIONS.PORTCO && (
                        <Typography sx={{ mb: 3, textAlign: 'center' }}>
                          <Trans>
                            <b>{customerName}</b> is working with their top
                            portfolio companies to reduce the climate impact of
                            their business.
                          </Trans>
                        </Typography>
                      )}
                    <Typography sx={{ mb: 3, textAlign: 'center' }}>
                      <Trans>
                        To access your Watershed dashboard and collaborate with{' '}
                        {customerName}, please enter your email address.
                        Watershed will send you a link that you can use to
                        securely sign in through your email account.
                      </Trans>
                    </Typography>
                  </>
                ))}
              {authError === AuthError.PermissionsRequired && (
                <Callout
                  variant="info"
                  sx={{ mb: 3, textAlign: 'center' }}
                  description={
                    <Typography variant="body1">
                      <Trans>
                        You don't currently have access to Watershed. We've
                        emailed {adminName} (
                        <TextLink href={`mailto:${adminEmail}`}>
                          {adminEmail}
                        </TextLink>
                        ) to approve your request for access.
                      </Trans>
                    </Typography>
                  }
                />
              )}
              <LoginComponent />
            </Paper>

            <Stack
              justifyContent="space-evenly"
              direction="row"
              sx={{
                py: 2,
                px: 4,
                gap: 2,
              }}
            >
              <LoginFooter classes={classes} />
            </Stack>
          </>
        )}
      </Box>
    </RedirectAuthenticatedUsers>
  );
}

const LoginPageWithParams = withOptionalRouteParams(LoginPage, [
  'authError',
  'email',
  'adminName',
  'adminEmail',
  'watershedGeneric',
]);
function LoginPageWrapper() {
  useOwner(Teams.EnterpriseFoundations);
  return <LoginPageWithParams />;
}
export default LoginPageWrapper;
LoginPageWrapper.layout = Layouts.LoggedOut;

function DataPortalTerms({ email }: { email: string | undefined }) {
  useEffect(() => {
    // Important to track this impression for legal purposes
    Analytics.view('dataPortalTerms', {
      email,
    });
  }, [email]);
  return (
    <Typography>
      <Trans>
        By logging in to Watershed, I agree to the{' '}
        <TextLink newTab href={DATA_PORTAL_TERMS_URL}>
          Watershed Data Portal Terms of Service
        </TextLink>{' '}
        and the{' '}
        <TextLink newTab href={PRIVACY_POLICY_URL}>
          Watershed Privacy Policy
        </TextLink>
        .
      </Trans>
    </Typography>
  );
}
